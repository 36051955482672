import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useQueryClient } from 'react-query'
import queryString from 'query-string'

import Loading from '../components/loading'

import isBrowser from '../validators/isBrowser'

import {
    useAuth
} from '../contexts/auth'

const AuthCallback = ({ location }) => {
    const {
        authState,
        authService
    } = useAuth()

    const queryClient = useQueryClient()

    useEffect(() => {
        if ( !isBrowser() ) return

        if ( authState.token ) {
            queryClient.invalidateQueries()
            return navigate('/app')
        }

        if ( !location.hash ) {
            return navigate('/')
        }

        const { id_token } = queryString.parse( location.hash )

        if ( !id_token ) {
            return navigate('/')
        }

        const handleAuth = async ( token ) => {
            try {
                await authService.authToken( token )
            } catch ( e ) {
                navigate('/')
            }
        }
        
        handleAuth( id_token )
    }, [ authState, authService, queryClient, location ])
    return <Loading/>
}

export default AuthCallback